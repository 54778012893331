.benefit-tracker {
  &__value {
    font-size: 4rem;
    font-weight: bold;
    color: #3f4041;
  }

  &__panel--secondary {
    background-color: #e5e5e5;
    color: #3f4041;
  }
}
